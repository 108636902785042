import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../state/store';

export type LocationData = {
  locationId: string;
  timezone: string;
};

export type VehicleData = {
  vehicleId: string;
  vehicleName: string;
  vehicleLicensePlate: string;
};

type TrailerData = {
  trailerEnabled: boolean;
  trailerLicensePlate?: string;
};

export type EntryInstructionsLanguage =
  | 'EN'
  | 'FR'
  | 'RU'
  | 'DA'
  | 'ES'
  | 'CS'
  | 'IT'
  | 'HU'
  | 'DE'
  | 'RO'
  | 'PL'
  | 'NL'
  | 'PT'
  | 'UK';

export type BookingDetails = {
  trailerData?: TrailerData;
  arrival?: string;
  departure?: string;
  driverName?: string;
  tripReference?: string;
  entryInstructionsLanguage?: EntryInstructionsLanguage;
  estimatedPrice?: string;
};

export type ParkingBookingState = BookingDetails & {
  locationData?: LocationData;
  vehicleData?: VehicleData;
};

const initialState: ParkingBookingState = {};

export const parkingBookingSlice = createSlice({
  name: 'parkingBooking',
  initialState,
  reducers: {
    enterBookingFlow: (state, action: PayloadAction<LocationData>) => {
      state = { ...initialState };
      state.locationData = action.payload;
      return state;
    },
    resetBookingState: () => initialState,
    setLocationData: (state, action: PayloadAction<LocationData>) => {
      state.locationData = action.payload;
    },
    setVehicleData: (state, action: PayloadAction<VehicleData>) => {
      state.vehicleData = {
        vehicleId: action.payload.vehicleId,
        vehicleName: action.payload.vehicleName,
        vehicleLicensePlate: action.payload.vehicleLicensePlate,
      };
    },
    setBookingDetails: (state, action: PayloadAction<BookingDetails>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const {
  enterBookingFlow,
  resetBookingState,
  setVehicleData,
  setLocationData,
  setBookingDetails,
} = parkingBookingSlice.actions;
export const getParkingBookingData = (state: RootState) => state.parkingBooking;
