import React from 'react';
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import { useCustomIntl } from '../i18n/i18n';

export const NoDataPlaceholder: React.FC = () => {
  const intl = useCustomIntl();

  return (
    <NotFoundState
      headline={intl.formatMessage({
        id: 'common.noDataFound.headline',
      })}
      message={intl.formatMessage({ id: 'common.noDataFound.message' })}
    />
  );
};
