import React from 'react';
import { TranslationsKeys, useCustomIntl } from '../../i18n/i18n';
import TableSearch from '@rio-cloud/rio-uikit/TableSearch';
import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';

type TableToolbarProps = {
  searchBarValue: string;
  onSearchBarValueChanged: (value: string) => void;
};

export const TableSearchToolbar: React.FC<TableToolbarProps> = ({
  searchBarValue,
  onSearchBarValueChanged,
}) => {
  const intl = useCustomIntl();
  const getLabel = (id: TranslationsKeys) => {
    return intl.formatMessage({
      id,
    });
  };

  return (
    <TableToolbar>
      <div
        className="table-toolbar-container"
        data-testid={'table-toolbar'}
      >
        <div className="table-toolbar-group-left">
          <TableSearch
            value={searchBarValue}
            onChange={onSearchBarValueChanged}
            placeholder={getLabel('common.table.search')}
          />
        </div>
      </div>
    </TableToolbar>
  );
};
