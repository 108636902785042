import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { CustomFormattedMessage } from '../../../i18n/i18n';
import { useNavigate } from 'react-router-dom';
import { PARKING_BOOKING_DETAILS_ROUTE } from '../../../routing/routes';
import { Grid } from '../../../components/pageGrid/Grid';
import { GridHeadline } from '../../../components/pageGrid/GridHeadline';
import { GridPanel } from '../../../components/pageGrid/GridPanel';
import { BookingSummaryDetails } from './BookingSummaryDetails';
import { BookingSummaryLegalNotice } from './BookingSummaryLegalNotice';
import { BookingSummaryNote } from './BookingSummaryNote';
import { useState } from 'react';
import classNames from 'classnames';
import { Alert, AlertType } from '../../../components/Alert';

export const BookingSummaryPage = () => {
  const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);

  const navigate = useNavigate();

  const toBookingDetails = () => {
    navigate(PARKING_BOOKING_DETAILS_ROUTE);
  };

  const handleConfirmation = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setIsSuccess(true);
    }, 1000);
  };

  return (
    <>
      <div data-testid="parking-booking-summary-content">
        <Grid
          columns={2}
          className={'align-items-top margin-bottom-50'}
        >
          <GridHeadline columns={2}>
            <CustomFormattedMessage id={'bookingSummary.headline'} />
          </GridHeadline>
          <BookingSummaryDetails />
          <GridPanel columns={2}>
            <BookingSummaryNote>
              <CustomFormattedMessage id={'bookingSummary.note'} />
            </BookingSummaryNote>
          </GridPanel>
        </Grid>
        {!isSuccess ? (
          <>
            <Grid
              columns={1}
              className={'align-items-center margin-bottom-20'}
            >
              <BookingSummaryLegalNotice />
              <GridPanel columns={1}>
                <BookingSummaryNote>
                  <CustomFormattedMessage id={'bookingSummary.cancelation'} />
                </BookingSummaryNote>
              </GridPanel>
            </Grid>
            <Button
              bsStyle="default"
              className={'margin-right-10'}
              onClick={toBookingDetails}
            >
              <CustomFormattedMessage id={'common.back'} />
            </Button>
            <Button
              bsStyle="primary"
              className={classNames({
                'btn-loading disabled': isLoading,
              })}
              onClick={handleConfirmation}
            >
              <CustomFormattedMessage
                id={'bookingSummary.button.confirmBooking'}
              />
            </Button>
          </>
        ) : (
          <>
            <Alert
              headline={
                <CustomFormattedMessage
                  id={'bookingSummary.success.headline'}
                />
              }
              body={
                <CustomFormattedMessage id={'bookingSummary.success.note'} />
              }
              type={AlertType.SUCCESS}
              className={'margin-bottom-20'}
            />
            <Button bsStyle="primary">
              <CustomFormattedMessage
                id={'bookingSummary.button.bookingOverview'}
              />
            </Button>
          </>
        )}
      </div>
    </>
  );
};
