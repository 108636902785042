import { SelectOption } from '@rio-cloud/rio-uikit/components/selects/BaseSelectDropdown';
import Switch from '@rio-cloud/rio-uikit/Switch';
import { CustomFormattedMessage, useCustomIntl } from '../../../i18n/i18n';
import Select from '@rio-cloud/rio-uikit/Select';
import Button from '@rio-cloud/rio-uikit/Button';
import {
  BookingDetails,
  EntryInstructionsLanguage,
  getParkingBookingData,
  setBookingDetails,
} from '../state/parkingBookingSlice';
import {
  PARKING_BOOKING_SUMMARY_ROUTE,
  PARKING_BOOKING_VEHICLE_SELECTION_ROUTE,
} from '../../../routing/routes';
import { useAppDispatch, useAppSelector } from '../../../state/store';
import { useNavigate } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormField } from '../../../components/form/FormField';
import moment, { Moment } from 'moment-timezone';
import { OptionalLabel } from '../../../components/form/OptionalLabel';
import { SchedulerDetails } from './SchedulerDetails';
import { DateUtils } from '../../../components/utils/DateUtils';

export type Form = {
  trailer: boolean;
  trailerLicensePlate?: string;
  scheduler: {
    arrivalDate?: Moment;
    arrivalTime?: Moment;
    departureDate?: Moment;
    departureTime?: Moment;
  };
  driver?: string;
  tripReference?: string;
  entryInstructionsLanguage: SelectOption;
};

const parseMoment = (value?: string): Moment | undefined => {
  return value ? moment.parseZone(value) : undefined;
};

export const BookingDetailsForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const intl = useCustomIntl();

  const ENGLISH = {
    id: 'EN' as EntryInstructionsLanguage,
    label: intl.formatMessage({ id: 'language.english' }),
  };

  const supportedLanguages: SelectOption[] = [ENGLISH];

  const parkingBookingData = useAppSelector(getParkingBookingData);
  const timezone =
    parkingBookingData.locationData?.timezone ||
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  const arrival = parseMoment(parkingBookingData.arrival);
  const departure = parseMoment(parkingBookingData.departure);

  const form = useForm<Form>({
    defaultValues: {
      trailer: parkingBookingData.trailerData?.trailerEnabled,
      trailerLicensePlate: parkingBookingData.trailerData?.trailerLicensePlate,
      scheduler: {
        arrivalDate: arrival,
        arrivalTime: arrival,
        departureDate: departure,
        departureTime: departure,
      },
      driver: parkingBookingData.driverName,
      tripReference: parkingBookingData.tripReference,
      entryInstructionsLanguage: ENGLISH,
    },
  });

  const { handleSubmit, control, register, formState, watch } = form;

  const hasTrailer = watch('trailer');

  const errors = formState.errors;

  const onSubmit: SubmitHandler<Form> = (formData: Form) => {
    const bookingDetails: BookingDetails = {
      trailerData: {
        trailerEnabled: formData.trailer || false,
        trailerLicensePlate: formData.trailer
          ? formData.trailerLicensePlate
          : undefined,
      },
      arrival: DateUtils.parseDateTime(
        timezone,
        formData.scheduler.arrivalDate,
        formData.scheduler.arrivalTime,
      ),
      departure: DateUtils.parseDateTime(
        timezone,
        formData.scheduler.departureDate,
        formData.scheduler.departureTime,
      ),
      driverName: formData.driver,
      tripReference: formData.tripReference
        ? formData.tripReference
        : undefined,
      entryInstructionsLanguage: formData.entryInstructionsLanguage
        .id as EntryInstructionsLanguage,
      estimatedPrice: '12.50 €',
    };

    dispatch(setBookingDetails(bookingDetails));
    navigate(PARKING_BOOKING_SUMMARY_ROUTE);
  };

  const toVehicleSelection = () => {
    navigate(PARKING_BOOKING_VEHICLE_SELECTION_ROUTE);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={'margin-bottom-10'}>
        <Controller
          name={'trailer'}
          render={({ field: { onChange, value } }) => (
            <Switch
              checked={value}
              onChange={onChange}
            >
              <CustomFormattedMessage id={'common.label.trailer'} />
            </Switch>
          )}
          control={control}
        />
      </div>
      {hasTrailer && (
        <FormField
          error={errors.trailerLicensePlate}
          data-testid={'booking-details-form-trailer-license-plate'}
        >
          <label htmlFor="trailerLicensePlate">
            <CustomFormattedMessage id={'common.label.trailerLicensePlate'} />
          </label>
          <input
            id="trailerLicensePlate"
            type="text"
            className="form-control"
            {...register('trailerLicensePlate', {
              required: {
                value: true,
                message: intl.formatMessage({
                  id: 'common.errorMessage.required',
                }),
              },
            })}
          />
        </FormField>
      )}
      <SchedulerDetails
        form={form}
        timezone={timezone}
        errors={errors}
      />
      <FormField
        error={errors.driver}
        data-testid={'booking-details-form-driver'}
      >
        <label htmlFor="driver">
          <CustomFormattedMessage id={'common.label.driver'} />
        </label>
        <input
          id="driver"
          type="text"
          className="form-control"
          {...register('driver', {
            required: {
              value: true,
              message: intl.formatMessage({
                id: 'common.errorMessage.required',
              }),
            },
          })}
        />
      </FormField>
      <FormField
        error={errors.tripReference}
        data-testid={'booking-details-form-trip-reference'}
      >
        <label htmlFor="tripReference">
          <CustomFormattedMessage id={'common.label.tripReference'} />
          <OptionalLabel className={'padding-left-5'} />
        </label>
        <input
          id="tripReference"
          type="text"
          className="form-control"
          {...register('tripReference')}
        />
      </FormField>
      <FormField
        error={errors.entryInstructionsLanguage}
        className={'margin-bottom-20'}
        data-testid={'booking-details-form-entry-instruction-language'}
      >
        <label htmlFor="entryInstructionsLanguage">
          <CustomFormattedMessage
            id={'common.label.entryInstructionsLanguage'}
          />
        </label>
        <Controller
          name={'entryInstructionsLanguage'}
          render={({ field: { onChange, value } }) => (
            <Select
              id="entryInstructionsLanguage"
              options={supportedLanguages}
              hasError={!!errors.entryInstructionsLanguage}
              onChange={onChange}
              value={value ? [value.id] : []}
            />
          )}
          control={control}
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: 'common.errorMessage.required',
              }),
            },
          }}
        />
      </FormField>
      <Button
        bsStyle="default"
        onClick={toVehicleSelection}
        className={'margin-right-15'}
      >
        <CustomFormattedMessage id={'common.back'} />
      </Button>
      <Button
        bsStyle="primary"
        type={'submit'}
        disabled={Object.keys(errors).length > 0}
      >
        <CustomFormattedMessage id={'common.next'} />
      </Button>
    </form>
  );
};
