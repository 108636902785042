import { Vehicle } from './models';

export const MockedVehicles: Vehicle[] = [
  {
    vehicleId: 'e5641b02-5aae-4a98-b892-89bd66aac1b4',
    vehicleName: 'Data Player MAN eTruck 1',
    vehicleVin: 'WMA06UFM000000003',
    vehicleLicensePlate: 'H-IA-462',
    vehicleType: 'Truck',
  },
  {
    vehicleId: 'ca1f6d20-615a-4879-a87d-1ff05a07c429',
    vehicleName: 'Data Player MAN eTruck 2',
    vehicleVin: 'WMA20HFM000000004',
    vehicleLicensePlate: 'H-IA-64',
    vehicleType: 'Truck',
  },
  {
    vehicleId: 'cf212add-3479-4350-8675-b007a6c5645f',
    vehicleName: 'Blue Lion',
    vehicleVin: 'WMA20HFM000012345',
    vehicleLicensePlate: 'M-AN-56',
    vehicleType: 'Truck',
  },
  {
    vehicleId: '93f5d50d-d87a-42e1-b787-99b9f5865d67',
    vehicleName: 'Weißer Stern',
    vehicleVin: 'WDB963403ZU994123',
    vehicleLicensePlate: 'H-PT-11',
    vehicleType: 'Truck',
  },
  {
    vehicleId: 'c2a1748b-8f0f-41d1-9e9d-56bceff4f0a2',
    vehicleName: 'Roter Stern',
    vehicleVin: 'WDB963403ZU994125',
    vehicleLicensePlate: 'H-GT-57',
    vehicleType: 'Truck',
  },
  {
    vehicleId: '68c9a412-23a6-4940-b1c6-d64df1fbe5a7',
    vehicleName: 'DataPlayer MAN Truck MY24 2',
    vehicleVin: 'WMA21FFM000000002',
    vehicleLicensePlate: 'M-PK-89',
    vehicleType: 'Truck',
  },
  {
    vehicleId: '8e5e665e-6587-445f-acfc-51f72d015b9b',
    vehicleName: 'Asphalt Avenger',
    vehicleVin: 'YS3DF78K527013788',
    vehicleLicensePlate: 'H-PL-79',
    vehicleType: 'Truck',
  },
  {
    vehicleId: '30c4de6d-5055-4eda-8f2f-c4963be193ed',
    vehicleName: 'DataPlayer MAN Truck MY24 1',
    vehicleVin: 'WMA06KFM000000001',
    vehicleLicensePlate: 'M-AN-97',
    vehicleType: 'Truck',
  },
  {
    vehicleId: '3f4d15a4-dbd5-4423-850d-b12eb11f9c20',
    vehicleName: 'Bergsteiger',
    vehicleVin: 'LP211434394738292',
    vehicleLicensePlate: 'M-PR-90',
    vehicleType: 'Truck',
  },
  {
    vehicleId: '11bccf6d-c84d-4b1b-b0dd-5f4e257eb334',
    vehicleName: 'Green Lion',
    vehicleVin: 'WMA20HFM000012347',
    vehicleLicensePlate: 'M-AN-45',
    vehicleType: 'Truck',
  },
  {
    vehicleId: '7b9a0079-acc4-4e55-8416-04dfe19906c1',
    vehicleName: 'Red Lion',
    vehicleVin: 'WMA20HFM000012346',
    vehicleLicensePlate: 'M-AN-24',
    vehicleType: 'Truck',
  },
  {
    vehicleId: '3b8e590a-05e0-4476-a1df-0c12f080d037',
    vehicleName: 'Gelber Stern',
    vehicleVin: 'WDB963403ZU994124',
    vehicleLicensePlate: 'H-LK-52',
    vehicleType: 'Truck',
  },
  {
    vehicleId: '4ee5c825-3049-4f70-a60b-63be58fb1ea7',
    vehicleName: 'Trailblazer',
    vehicleVin: 'YS3DF78K527013789',
    vehicleLicensePlate: 'M-UF-56',
    vehicleType: 'Truck',
  },
  {
    vehicleId: '75c7b6ed-c7b5-4b56-b5d8-96f7e31bcb1e',
    vehicleName: 'Fracht-Falke',
    vehicleVin: 'RRP1295F587398498',
    vehicleLicensePlate: '',
    vehicleType: 'Truck',
  },
  {
    vehicleId: 'e5780bcb-92e7-4622-93da-d1979eb9a48e',
    vehicleName: 'Thunder Truck',
    vehicleVin: 'RTF77RTK495837304',
    vehicleLicensePlate: 'M-LL-18',
    vehicleType: 'Truck',
  },
  {
    vehicleId: '8bfa6355-af3f-4e0d-93b8-c1bb8be7aace',
    vehicleName: 'Road Runner',
    vehicleVin: 'RRP1295F587398497',
    vehicleLicensePlate: 'M-IO-57',
    vehicleType: 'Truck',
  },
];
