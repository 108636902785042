import { ReactElement } from 'react';
import { TableHeaderCell } from './TableHeaderCell';

export type HeaderColumn<T> = {
  id: keyof T;
  label: string;
};

type TableHeaderProps<T> = {
  columns: HeaderColumn<T>[];
};

export const TableHeader = <T extends unknown>({
  columns,
}: TableHeaderProps<T>): ReactElement | null => {
  return (
    <thead>
      <tr>
        {columns.map((column) => {
          return (
            <TableHeaderCell
              key={column.id.toString()}
              label={column.label}
            />
          );
        })}
      </tr>
    </thead>
  );
};
