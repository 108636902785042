import { ReactElement } from 'react';
import classNames from 'classnames';
import { TableColumn, TableRow } from './TableRow';
import { TableHeader } from './TableHeader';
import { NoDataPlaceholder } from '../NoDataPlaceholder';

const tableClasses = classNames(
  'table',
  'table-column-overflow-hidden',
  'table-bordered',
  'table-sticky-in-container',
  'table-head-filled',
);

type RowMeta = {
  key: string;
  enabled: boolean;
};
export type RowData<T> = T & RowMeta;

type TableProps<T> = {
  columns: TableColumn<T>[];
  rowData: RowData<T>[];
  onRowClicked: (key: string) => void;
  isLoading?: boolean;
  highlightedRow?: string;
};

export const Table = <T extends object>({
  columns,
  rowData,
  onRowClicked,
  isLoading = false,
  highlightedRow,
}: TableProps<T>): ReactElement | null => {
  if (rowData.length === 0) {
    return <NoDataPlaceholder />;
  }

  const isRowHighlighted = (key: string) => {
    return highlightedRow === key;
  };

  return (
    <table className={tableClasses}>
      <TableHeader columns={columns} />
      <tbody>
        {rowData.map((row) => {
          const key = row.key;
          const highlighted = isRowHighlighted(key);

          return (
            <TableRow
              isLoading={isLoading}
              key={key}
              columns={columns}
              model={row}
              onClick={() => onRowClicked(key)}
              highlighted={highlighted}
              disabled={!row.enabled}
            />
          );
        })}
      </tbody>
    </table>
  );
};
