import Button from '@rio-cloud/rio-uikit/Button';
import {
  CustomFormattedMessage,
  TranslationsKeys,
  useCustomIntl,
} from '../../i18n/i18n';
import {
  getParkingBookingData,
  setVehicleData,
} from './state/parkingBookingSlice';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../state/store';
import {
  locationDetailsRoute,
  PARKING_BOOKING_DETAILS_ROUTE,
} from '../../routing/routes';
import { RowData, Table } from '../../components/table/Table';
import { TableColumn } from '../../components/table/TableRow';
import { useMemo, useState } from 'react';
import { MockedVehicles } from '../../data/MockedVehicles';
import { GridHeadline } from '../../components/pageGrid/GridHeadline';
import { TableSearchToolbar } from '../../components/table/TableSearchToolbar';
import { Vehicle } from '../../data/models';

export const SelectVehiclePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const intl = useCustomIntl();
  const locationId = useAppSelector(getParkingBookingData).locationData
    ?.locationId!;
  const selectedVehicleId = useAppSelector(getParkingBookingData).vehicleData
    ?.vehicleId;

  const initialFilteringState = {
    searchBarValue: '',
  };

  const [filtering, setFiltering] = useState(initialFilteringState);

  const handleSearchBarValueChanged = (value: string) => {
    setFiltering({ ...filtering, searchBarValue: value });
  };

  const handleVehicleClicked = (id: string) => {
    const selectedVehicle = MockedVehicles.find(
      (vehicle) => vehicle.vehicleId === id,
    );

    if (selectedVehicle) {
      dispatch(setVehicleData(selectedVehicle));
    }
  };

  const toParkingDetailsForm = () => {
    navigate(PARKING_BOOKING_DETAILS_ROUTE);
  };

  const toLocationDetails = () => {
    navigate(locationDetailsRoute(locationId));
  };

  type VehicleRow = {
    vehicleName: string;
    vehicleVin: string;
    vehicleLicensePlate: string;
    vehicleType: string;
  };

  const getLabel = (id: TranslationsKeys) => {
    return intl.formatMessage({
      id,
    });
  };

  const columns: TableColumn<VehicleRow>[] = [
    {
      id: 'vehicleName',
      label: getLabel('common.vehicle.name'),
      renderer: (vehicle: VehicleRow) => <>{vehicle.vehicleName}</>,
    },
    {
      id: 'vehicleVin',
      label: getLabel('common.vehicle.vin'),
      renderer: (vehicle: VehicleRow) => <>{vehicle.vehicleVin}</>,
    },
    {
      id: 'vehicleLicensePlate',
      label: getLabel('common.vehicle.licensePlate'),
      renderer: (vehicle: VehicleRow) => <>{vehicle.vehicleLicensePlate}</>,
    },
    {
      id: 'vehicleType',
      label: getLabel('common.vehicle.type'),
      renderer: (vehicle: VehicleRow) => <>{vehicle.vehicleType}</>,
    },
  ];

  const filterBySearchBarValue = (vehicle: Vehicle) => {
    const filterOn = [
      vehicle.vehicleVin,
      vehicle.vehicleName,
      vehicle.vehicleLicensePlate,
    ];
    return filterOn.some((value) =>
      new RegExp(filtering.searchBarValue, 'i').test(value),
    );
  };

  const filteredVehicles = useMemo(() => {
    return MockedVehicles.filter(filterBySearchBarValue);
  }, [filtering]);

  const rowData: RowData<VehicleRow>[] = useMemo(
    () =>
      filteredVehicles.map((vehicle) => ({
        key: vehicle.vehicleId,
        enabled: !!vehicle.vehicleLicensePlate,
        vehicleName: vehicle.vehicleName,
        vehicleVin: vehicle.vehicleVin,
        vehicleLicensePlate: vehicle.vehicleLicensePlate,
        vehicleType: vehicle.vehicleType,
      })),
    [filteredVehicles],
  );

  return (
    <>
      <GridHeadline columns={1}>
        <CustomFormattedMessage id={'vehicleSelection.selectVehicle'} />
      </GridHeadline>
      <TableSearchToolbar
        searchBarValue={filtering.searchBarValue}
        onSearchBarValueChanged={handleSearchBarValueChanged}
      />
      <div
        className="table-responsive overflow-y-auto margin-bottom-15"
        data-testid="parking-booking-vehicles-table"
      >
        <Table
          columns={columns}
          rowData={rowData}
          highlightedRow={selectedVehicleId}
          onRowClicked={(key) => {
            handleVehicleClicked(key);
          }}
          isLoading={false}
        />
      </div>
      <div className="margin-bottom-20">
        <CustomFormattedMessage
          id="vehicleSelection.hint" // Type-checked as TranslationsKeys
          values={{
            rioAssetManagement: (
              <a
                href="https://administration.assets.rio.cloud/#/assets"
                target="_blank"
                rel="noreferrer"
              >
                {getLabel('vehicleSelection.rioAssetManagement')}
              </a>
            ),
          }}
        />
      </div>
      <Button
        bsStyle="default"
        className={'margin-right-10'}
        onClick={toLocationDetails}
      >
        <CustomFormattedMessage id={'common.back'} />
      </Button>
      <Button
        bsStyle="primary"
        onClick={toParkingDetailsForm}
        disabled={!selectedVehicleId}
      >
        <CustomFormattedMessage id={'common.next'} />
      </Button>
    </>
  );
};
