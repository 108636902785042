import { VehicleDetails } from './VehicleDetails';
import { BookingDetailsForm } from './BookingDetailsForm';
import { VehicleSelection } from './VehicleSelection';

export const BookingDetailsPage = () => {
  return (
    <>
      <VehicleSelection />
      <VehicleDetails />
      <BookingDetailsForm />
    </>
  );
};
