import { TranslationsKeys } from './i18n';
import { LocationService } from '../data/models';
import { Weekday } from '../codegen/location';

type EnumTranslation<T extends string> = {
  [key in T]: TranslationsKeys;
};

export const PARKING_LOCATION_SERVICE_TO_TRANSLATION_KEY: EnumTranslation<LocationService> =
  {
    COOLING_MOTORS_ALLOWED:
      'locationDetails.locationServices.COOLING_MOTORS_ALLOWED',
    ELECTRIC_COOLING_CHARGING:
      'locationDetails.locationServices.ELECTRIC_COOLING_CHARGING',
    ADR_POSSIBLE: 'locationDetails.locationServices.ADR_POSSIBLE',
    LONG_TIME_PARKING: 'locationDetails.locationServices.LONG_TIME_PARKING',
    DECOUPLING_TRAILERS: 'locationDetails.locationServices.DECOUPLING_TRAILERS',
    FENCE: 'locationDetails.locationServices.FENCE',
    SPREAD_LIGHTING: 'locationDetails.locationServices.SPREAD_LIGHTING',
    CAMERA_SECURITY: 'locationDetails.locationServices.CAMERA_SECURITY',
    GUARD: 'locationDetails.locationServices.GUARD',
    TOILET: 'locationDetails.locationServices.TOILET',
    SHOWER: 'locationDetails.locationServices.SHOWER',
    WIFI: 'locationDetails.locationServices.WIFI',
    HOTEL: 'locationDetails.locationServices.HOTEL',
    RESTAURANT: 'locationDetails.locationServices.RESTAURANT',
  };

export const WEEKDAYS_TO_TRANSLATION_KEY: EnumTranslation<Weekday> = {
  MONDAY: 'common.weekday.monday',
  TUESDAY: 'common.weekday.tuesday',
  WEDNESDAY: 'common.weekday.wednesday',
  THURSDAY: 'common.weekday.thursday',
  FRIDAY: 'common.weekday.friday',
  SATURDAY: 'common.weekday.saturday',
  SUNDAY: 'common.weekday.sunday',
};
