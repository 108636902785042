import { ReactElement } from 'react';
import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';
import classNames from 'classnames';

export type TableColumn<T> = {
  id: keyof T;
  label: string;
  renderer: (model: T) => ReactElement;
};

type TableRowProps<T> = {
  columns: TableColumn<T>[];
  model: T;
  highlighted: boolean;
  onClick: () => void;
  disabled: boolean;
  isLoading?: boolean;
};

export const TableRow = <T extends unknown>({
  columns,
  model,
  highlighted,
  onClick,
  disabled,
  isLoading = false,
}: TableRowProps<T>): ReactElement | null => {
  const handleClickOnRow = () => {
    if (disabled || isLoading) {
      return;
    }

    onClick();
  };

  const cells = columns.map((column, index) => {
    const render = column.renderer;
    return (
      <td
        key={index}
        data-field={column}
      >
        {isLoading ? <ContentLoader /> : render(model)}
      </td>
    );
  });

  const classes = classNames(
    `${!isLoading ? 'cursor-pointer' : ''}`,
    `${highlighted && !isLoading ? 'active' : ''}`,
    `${disabled ? 'disabled' : ''}`,
  );

  return (
    <tr
      className={classes}
      onClick={handleClickOnRow}
    >
      {cells}
    </tr>
  );
};
