import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { routeStorage } from '../routing/routeStorage';
import { ROOT_ROUTE } from '../routing/routes';

export const RedirectPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const initialRoute = routeStorage.getRoute();
    const route = initialRoute ? initialRoute : ROOT_ROUTE;

    navigate(route, { replace: true });
  }, []);

  return <></>;
};
