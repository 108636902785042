import { Grid } from '../../../components/pageGrid/Grid';
import { GridHeadline } from '../../../components/pageGrid/GridHeadline';
import { CustomFormattedMessage } from '../../../i18n/i18n';
import { GridTwoPanels } from '../../../components/pageGrid/GridTwoPanels';
import { useAppSelector } from '../../../state/store';
import { getParkingBookingData } from '../state/parkingBookingSlice';

export const VehicleDetails = () => {
  const parkingBookingData = useAppSelector(getParkingBookingData);
  const vehicleData = parkingBookingData.vehicleData;

  return (
    <Grid
      columns={2}
      className={'align-items-center margin-bottom-20'}
    >
      <GridHeadline columns={2}>
        <CustomFormattedMessage id={'bookingDetailsPage.headline'} />
      </GridHeadline>
      {vehicleData && (
        <>
          <GridTwoPanels
            leftContent={
              <CustomFormattedMessage id={'common.label.vehicleName'} />
            }
            rightContent={vehicleData.vehicleName}
            leftColumns={1}
            rightColumns={1}
          />
          <GridTwoPanels
            leftContent={
              <CustomFormattedMessage id={'common.label.vehicleLicensePlate'} />
            }
            rightContent={vehicleData.vehicleLicensePlate}
            leftColumns={1}
            rightColumns={1}
          />
        </>
      )}
    </Grid>
  );
};
