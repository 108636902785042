import { useMemo } from 'react';
import Select from '@rio-cloud/rio-uikit/Select';
import { Grid } from '../../../components/pageGrid/Grid';
import { GridPanel } from '../../../components/pageGrid/GridPanel';
import { SelectOption } from '@rio-cloud/rio-uikit/components/selects/BaseSelectDropdown';
import { CustomFormattedMessage } from '../../../i18n/i18n';
import { InfoPopup } from '../../../components/InfoPopup';
import { GridSubHeadline } from '../../../components/pageGrid/GridSubHeadline';
import { useGetVehiclesQuery } from '../../../codegen/vehicles';

export const VehicleSelection = () => {
  const { isLoading, data: vehicles } = useGetVehiclesQuery();

  const vehicleOptions: SelectOption[] = useMemo(
    () =>
      vehicles!.items.map((vehicle) => ({
        id: vehicle.id,
        label: vehicle.name,
      })),
    [isLoading, vehicles],
  );

  return (
    <Grid
      columns={2}
      className={'align-items-center margin-bottom-20'}
    >
      <GridSubHeadline columns={1}>
        <CustomFormattedMessage id={'common.label.vehicle'} />
      </GridSubHeadline>
      <GridSubHeadline columns={1}>
        <InfoPopup>
          <CustomFormattedMessage
            id={'bookingDetailsPage.vehicle.noteText'}
            values={{
              link: (parts: string) => (
                <a
                  className={'text-decoration-underline'}
                  href={'https://administration.assets.rio.cloud/#/assets'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {parts}
                </a>
              ),
            }}
          />
        </InfoPopup>
      </GridSubHeadline>
      <GridPanel columns={2}>
        <Select
          data-testid={'parking-booking-vehicles-selector'}
          id={'vehicleSelection'}
          placeholder={'Please select Vehicle'}
          options={vehicleOptions}
          useFilter
        />
      </GridPanel>
    </Grid>
  );
};
